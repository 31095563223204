import styles from "./BillDetailReplacement.module.css";
import useCartTotals from "../../hooks/useCartTotal";
import { LuCalendarCheck2 } from "react-icons/lu";
import { MdOutlineAccessTime } from "react-icons/md";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import TaxDisplayPopup from "../elements/common/TaxDisplayPopup";
import { showOrderType } from "../../constants";
import { Tooltip } from "react-tooltip";
const BillDetail = ({ list, orderDetail, orderType }) => {
  const { total, totalItemPrice, handlingCharges } = useCartTotals(list);
  const [taxes, setTaxes] = useState();
  const [showTaxes, setShowTaxes] = useState(false);
  useEffect(() => {
    const itemIotal =
      (orderDetail.total * 100) /
      (100 + (orderDetail?.taxes?.cgst + orderDetail?.taxes?.sgst));

    const taxes =
      ((orderDetail?.taxes?.cgst + orderDetail?.taxes?.sgst) / 100) * itemIotal;
    setTaxes(taxes);
  }, [orderDetail]);

  return (
    <div>
      <div className="bill-details py-2 ">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className={`fw-normal mx-1 h4 py-1 ${styles.billdetail}`}>
            {" "}
            <span>BILL DETAILS</span>
          </h6>
          <span
            className="d-flex align-items-center justify-content-center px-2 float-end"
            style={{
              color: "#FFF",
              backgroundColor: "#99a123",
              borderRadius: 5,
            }}
          >
            {" "}
            {showOrderType[orderType] ?? "df"}
          </span>
        </div>
        <div className={`${styles.details} border px-3 mt-1`}>
          <p
            className={`${styles.tablename} fs-5`}
            style={{ color: "var(--primary-color)" }}
          >
            {orderDetail?.tableName || orderDetail?.tableNumber}
          </p>
          <div className={`${styles.tablename} fs-6`}>
            <span className="mx-2">
              <LuCalendarCheck2 size={18} /> {orderDetail.orderDate}
            </span>
            <span>
              <MdOutlineAccessTime size={18} /> {orderDetail.orderTime}
            </span>
          </div>
          <hr style={{ backgroundColor: "rgba(142, 142, 142, 0.59)" }} />
          <div className={`${styles.tablename}`}>
            <div className="d-flex flex-row justify-content-between">
              <div>Item Total</div>
              <div>₹{totalItemPrice}</div>
            </div>
            {orderDetail?.payment?.discount > 0 && (
              <div className="d-flex flex-row justify-content-between">
                <div>Discount</div>
                <div>{`₹${orderDetail?.payment?.discount}`}</div>
              </div>
            )}
            {Boolean(taxes) && (
              <div className="d-flex flex-row justify-content-between my-2">
                <div onClick={() => setShowTaxes(true)}>
                  Taxes <BsFillInfoCircleFill size={17} color="grey" />
                </div>
                <div>₹{taxes.toFixed(2)}</div>
              </div>
            )}
            {Math.abs(orderDetail?.payment?.roundedOff) > 0 && (
              <div className="d-flex flex-row justify-content-between my-2">
                <div>Round off</div>
                <div>{(orderDetail?.payment?.roundedOff).toFixed(2)}</div>
              </div>
            )}
            {showTaxes && (
              <TaxDisplayPopup
                amount={orderDetail?.payment?.discount ? totalItemPrice - orderDetail?.payment?.discount : totalItemPrice}
                taxes={orderDetail?.taxes}
                setShowTaxes={setShowTaxes}
                showTaxes={showTaxes}
              />
            )}
          </div>
          <hr style={{ backgroundColor: "rgba(142, 142, 142, 0.59)" }} />
          <h6 className="fw-bold mb-0">
            Total Bill
            <span className="float-end  " style={{ color: "#fa4a0c" }}>
              ₹
              {orderDetail.orderStatus === "delivered" || orderDetail?.total
                ? orderDetail?.total
                : 0}
            </span>
          </h6>
          <h6 className="fw-bold mb-0">
            {orderDetail?.payment !== null && orderDetail?.payment?.paid
              ? "You Paid"
              : orderDetail.orderStatus === "cancelled"
              ? "Total Amt."
              : ""}
            {(orderDetail?.payment && orderDetail?.payment?.paid) ||
            orderDetail?.orderStatus === "cancelled" ? (
              <span className="float-end" style={{ color: "#fa4a0c" }}>
                ₹
                {orderDetail?.payment?.paid
                  ? orderDetail?.payment?.paid
                  : orderDetail?.total}
              </span>
            ) : (
              !orderDetail?.payment?.due &&
              !orderDetail?.payment?.waivedOff && (
                <span className="float-end" style={{ color: "#dc3545" }}>
                  Unpaid
                </span>
              )
            )}
          </h6>
          {Number.isFinite(orderDetail?.payment?.waivedOff) &&
            orderDetail?.payment?.waivedOff !== 0 && (
              <h6 className="fw-bold mb-0 ">
                Waived Off{" "}
                <BsFillInfoCircleFill
                  size={17}
                  color="grey"
                  data-tooltip-id="waived-off"
                />
                <span className="float-end  " style={{ color: "#fa4a0c" }}>
                  ₹{orderDetail?.payment?.waivedOff}
                </span>
              </h6>
            )}{" "}
          <Tooltip id="waived-off" place="right" mouseover={true}>
            {" "}
            {orderDetail?.payment?.waivedOffReason}
          </Tooltip>
          {Number.isFinite(orderDetail?.payment?.due) &&
            orderDetail?.payment?.due !== 0 && (
              <h6 className="fw-bold mb-0 ">
                Due Amount
                <span className="float-end  " style={{ color: "#fa4a0c" }}>
                  ₹{orderDetail?.payment?.due}
                </span>
              </h6>
            )}
        </div>
      </div>{" "}
      {/* {orderDetail.orderStatus === "received" ? (
        <div className="text-end py-2">
          <button className="btn btn-danger">Cancel Order</button>
        </div>
      ) : null} */}
    </div>
  );
};

export default BillDetail;
