import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  taxContainer: {
    marginTop: 12,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "right",
  },
  bold: {
    fontFamily: "Inter",
  },
});

const InvoiceThankYouMsg = ({ items, invoice }) => {
  const taxes = JSON.parse(localStorage.getItem("taxes"));
  const calculateTaxes = (total) => {
    const totalAfterDiscount = total - calculateDiscount(total);
    const cgst = (totalAfterDiscount * invoice?.taxes?.cgst) / 100;
    const sgst = (totalAfterDiscount * invoice?.taxes?.sgst) / 100;
    return cgst + sgst;
  };
  const total = items.reduce((total, item) => {
    return total + item.total * item.quantity;
  }, 0);
  const calculateDiscount = (total) => {
    return invoice?.discount;
  };

  return (
    <>
      <View style={styles.taxContainer}>
        <Text style={styles.reportTitle}>
          <Text style={styles.bold}>Subtotal:</Text>{" "}
          {Number.parseFloat(total).toFixed(2)}
        </Text>
        {invoice?.discount > 0 && (
          <Text style={styles.reportTitle}>
            <Text style={styles.bold}>Discount:</Text>{" "}
            {Number.parseFloat(calculateDiscount(total)).toFixed(2)}
          </Text>
        )}
        <Text style={styles.reportTitle}>
          <Text style={styles.bold}>Taxes:</Text>{" "}
          {calculateTaxes(total).toFixed(2)}
        </Text>
        {Math.abs(invoice?.roundedOff) > 0 && (
          <Text style={styles.reportTitle}>
            <Text style={styles.bold}>Round off:</Text>{" "}
            {Number.parseFloat(invoice?.roundedOff).toFixed(2)}
          </Text>
        )}
        <Text style={styles.reportTitle}>
          <Text style={styles.bold}>Total:</Text> &#x20B9;
          {`${Math.round(invoice?.total)}`}
        </Text>
      </View>
    </>
  );
};

export default InvoiceThankYouMsg;
